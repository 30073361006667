@import "src/assets/variables.scss";

.header-card {
    .initialize-request {
        cursor: pointer;
    }

    background: linear-gradient(-220deg,
        $primary-lighten-1 0%,
        $primary-lighten-1 70%,
        $primary-lighten-2 30%,
        $primary-lighten-2 80%);
    display: flex;
    justify-content: space-around;
    flex-direction: column;

    margin: 20px;

    border-radius: 3px;
    height: 200px;

    &_header {
        display: flex;
        margin-left: 30px;

        img {
            width: 100px;
        }

        &-headline {
            margin: 0 30px;
            align-self: center;

            h1 {
                color: $grey-lighten-5;
                font-family: Roboto;
                letter-spacing: 0.5px;
                font-weight: 500;
                font-size: $font-size-heading-5;
            }

            h2 {
                color: $grey-lighten-5;
                font-family: Roboto;
                letter-spacing: 0.25px;
                font-size: $font-size-body-1;
            }
        }
    }
}

@media (min-width: 0px) and (max-width: #{$mediaMinWidth - 1}) {
    .header-card {
        justify-content: space-around;
        flex-direction: column;
        margin: 20px 10px;

        &_header {
            flex-direction: column;
            justify-content: space-around;

            img {
                display: none;
            }

            &-headline {
                margin: 0 10px;

                h1 {
                    font-size: $font-size-body-2;
                }

                h2 {
                    font-size: $font-size-overline;
                }
            }
        }
    }
}

@media only screen and (min-width: $mediaMinWidth) and (max-width: #{$mediaBp2Width - 1}) {
    .header-card {
        margin: 20px 10px;
        justify-content: space-around;
        height: auto;

        &_header {
            img {
                width: 70px;
            }

            &-headline {
                margin-left: 18px;
                padding: 10px;

                h1 {
                    font-size: $font-size-body-2;
                }

                h2 {
                    font-size: $font-size-overline;
                }
            }
        }
    }
}

.cards-wrapper {
    min-width: auto;

    &_alert {
        color: $red;
        font-weight: 400;
        font-size: $font-size-body-1;
        border: 1.5px solid $red;
        background-color: $red-lighten-4;
        padding: 10px;
    }

    .font {
        font-family: Roboto !important;
    }
}

.welcome-card {
    .initialize-request {
        cursor: pointer;
    }

    @media (min-width: 0px) and (max-width: 479px) {
        background: linear-gradient(-220deg,
                $primary-lighten-1 0%,
                $primary-lighten-1 70%,
                $primary-lighten-2 30%,
                $primary-lighten-2 80%);
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        margin: 20px 10px;

        border-radius: 3px;
        height: 200px;

        &_header {
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            img {
                display: none;
            }

            &-headline {
                margin: 0 10px;

                h2 {
                    font-size: $font-size-body-2;
                    color: $grey-lighten-5;
                    font-family: Roboto;
                    letter-spacing: 0.5px;
                }

                h4 {
                    font-size: $font-size-overline;
                    color: $grey-lighten-5;
                    font-family: Roboto;
                    letter-spacing: 0.25px;
                }
            }
        }

        button {
            height: 30px;
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 20px;
            justify-content: center;
            border-radius: 4px;
            border: none;
            color: $primary-lighten-1;
            font-family: Roboto;
            font-size: 10px;
            font-weight: 500;
            letter-spacing: 1.25px;
            background-color: $default-white;

            .btn-img {
                width: 14px;
                height: 14px;
                filter: invert(68%) sepia(11%) saturate(2416%) hue-rotate(900deg) brightness(118%) contrast(119%);
                margin-right: 8px;
                opacity: 0.6;
            }

            &:hover {
                background-color: $primary-lighten-1;
                color: $default-white;

                .btn-img {
                    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(79deg) brightness(103%) contrast(102%) !important;
                }
            }
        }
    }

    @media only screen and (min-width: $mediaMinWidth) {
        background: linear-gradient(-220deg,
                $primary-lighten-1 0%,
                $primary-lighten-1 70%,
                $primary-lighten-2 30%,
                $primary-lighten-2 80%);
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 20px 10px;
        border-radius: 3px;

        &_header {
            display: flex;
            align-items: center;

            img {
                width: 70px;
            }

            &-headline {
                margin-left: 18px;
                padding: 10px;

                h2 {
                    font-size: $font-size-body-2;
                    color: $grey-lighten-5;
                    font-family: Roboto;
                    letter-spacing: 0.5px;
                }

                h4 {
                    font-size: $font-size-overline;
                    color: $grey-lighten-5;
                    font-family: Roboto;
                    letter-spacing: 0.25px;
                }
            }
        }

        button {
            width: 150px;
            height: 30px;
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 20px;
            justify-content: center;
            margin-right: 5px;
            border-radius: 4px;
            border: none;
            color: $primary-lighten-1;
            font-family: Roboto;
            font-size: 10px;
            font-weight: 500;
            letter-spacing: 1.25px;
            background-color: $default-white;

            .btn-img {
                width: 14px;
                height: 14px;
                filter: invert(68%) sepia(11%) saturate(2416%) hue-rotate(900deg) brightness(118%) contrast(119%);
                margin-right: 8px;
                opacity: 0.6;
            }

            &:hover {
                background-color: $primary-lighten-1;
                color: $default-white;

                .btn-img {
                    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(79deg) brightness(103%) contrast(102%) !important;
                }
            }
        }
    }

    @media only screen and (min-width: $mediaMaxWidth) {
        margin: 20px 15px 30px;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 3px;
        background: linear-gradient(-220deg,
                $primary-lighten-1 0%,
                $primary-lighten-1 70%,
                $primary-lighten-2 30%,
                $primary-lighten-2 80%);

        &_header {
            display: flex;
            align-items: center;

            img {
                height: 148px;
                width: 200px;
                margin-left: 18px;
            }

            &-headline {
                h2 {
                    color: $grey-lighten-5;
                    font-family: Roboto;
                    letter-spacing: 0.5px;
                    font-size: $font-size-heading-5;
                }

                h4 {
                    color: $grey-lighten-5;
                    font-family: Roboto;
                    letter-spacing: 0.25px;
                    font-size: $font-size-body-1;
                }
            }
        }

        button {
            width: 322px;
            height: 64px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 4px;
            border: none;
            color: $primary-lighten-1;
            font-family: Roboto;
            font-size: $font-size-body-1;
            font-weight: 500;
            letter-spacing: 1.25px;
            margin-right: 30px;
            background-color: $default-white;

            .btn-img {
                width: 20px;
                height: 20px;
                filter: invert(68%) sepia(11%) saturate(2416%) hue-rotate(900deg) brightness(118%) contrast(119%);
                margin-right: 8px;
                opacity: 0.6;
            }

            &:hover {
                background-color: $primary-lighten-1;
                color: $default-white;

                .btn-img {
                    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(79deg) brightness(103%) contrast(102%) !important;
                }
            }
        }
    }
}