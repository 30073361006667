// fxLayout
.flex-row {
    display: flex;
    flex-direction: row !important;
    box-sizing: border-box;
}

.flex-col {
    display: flex;
    flex-direction: column !important;
    box-sizing: border-box;
}

.flex-column-lt-md {
    @media (max-width: 959px) {
        flex-direction: column !important;

        .flex-25 {
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
    }
}

.flex-column-lt-xl {
    @media (max-width: 1200px) {
        flex-direction: column !important;

        .flex-25 {
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
    }
}

.gap-gt-sm {
    gap: 0;

    @media (min-width: 959px) {
        gap: 16px;
    }
}

.flex-hide-lt-md {
    @media (max-width: 959px) {
        display: none !important;
    }
}

/* Hide elements on screens larger than 600px (gt-sm) */
.flex-hide-gt-sm {
    @media (min-width: 959px) {
        display: none !important;
    }
}

.grid {
    display: grid;
}

.flex-wrap {
    flex-wrap: wrap;
}

// used with flex-row, when wrapping is desired.
.flex-col-xs {
    @media screen and (max-width: 599px) {
        flex-direction: column;
    }
}

// Switch from flex-row to flex-col on mobile.

// fxLayoutGap
.gap-4 {
    gap: 4px;
}

// fxLayoutGap="4px"
.gap-5 {
    gap: 5px;
}

.gap-8 {
    gap: 8px;
}

.gap-10 {
    gap: 10px;
}

.gap-12 {
    gap: 12px;
}

.gap-15 {
    gap: 15px;
}

.gap-16 {
    gap: 16px;
}

.gap-20 {
    gap: 20px;
}

.gap-24 {
    gap: 24px;
}

.gap-35 {
    gap: 35px;
}

// fxFlex
.flex-1 {
    flex: 1 1 0;
    box-sizing: border-box;
}

// fxFlex="15"
.flex-15 {
    flex: 0 0 100%;
    max-width: 15%;
    box-sizing: border-box;
}

// fxFlex="20"
.flex-20 {
    flex: 0 0 100%;
    max-width: 20%;
    box-sizing: border-box;
}

.flex-25 {
    flex: 0 0 100%;
    box-sizing: border-box;
    max-width: 25%;
}

// fxFlex="30"
.flex-30 {
    flex: 0 0 100%;
    max-width: 30%;
    box-sizing: border-box;
}

// fxFlex="32"
.flex-32 {
    flex: 0 0 100%;
    max-width: 32%;
    box-sizing: border-box;
}

// fxFlex="40"
.flex-40 {
    flex: 0 0 100%;
    box-sizing: border-box;
    max-width: 40%;
}

// fxFlex="50"
.flex-50 {
    flex: 0 0 100%;
    max-width: 50%;
    box-sizing: border-box;
}

// fxFlex="70"
.flex-70 {
    flex: 0 0 100%;
    max-width: 70%;
    box-sizing: border-box;
}

// fxFlex="100"
.flex-100 {
    flex: 0 0 100%;
    max-width: 100%;
    box-sizing: border-box;
}

// fxFlex="100%"
.flex-full-width {
    flex: 1 1 100%;
    max-width: 100%;
    /* Ensure it doesn't exceed 100% width */
    box-sizing: border-box;
}

// fxLayoutAlign
.flex-start-start {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
}

.flex-start-center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex-space-between {
    display: flex;
    place-content: stretch space-between;
    align-items: stretch;
}

.flex-space-between-start {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.flex-space-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

// fxLayoutAlign="space-between stretch"
.flex-space-between-stretch {
    display: flex;
    justify-content: space-between;
    /* Distribute items with equal space between them */
    align-items: stretch;
    /* Stretch items to fill the container vertically */
}

.flex-space-between-none {
    display: flex;
    justify-content: space-between;
    align-items: unset;
}

.flex-center-start {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.flex-center-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-center-end {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.flex-center-stretch {
    display: flex;
    justify-content: center;
    align-items: stretch;
}

.flex-space-around-center {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.flex-end-center {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

// fxLayoutAlign="end end"
.flex-end-end {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.flex-none-end {
    display: flex;
    justify-content: unset;
    /* Remove horizontal alignment */
    align-items: flex-end;
    /* Vertical alignment to the end */
}

.flex-end-none {
    display: flex;
    justify-content: flex-end;
    /* Horizontal alignment to the end */
    align-items: unset;
    /* Remove vertical alignment */
}

// fxLayoutAlign="end"
.flex-items-end {
    place-content: stretch flex-end;
    align-items: stretch;
    align-self: flex-end;
    box-sizing: border-box;
    display: flex;
}

// fxLayoutAlign="none"
.flex-layout-none {
    justify-content: initial;
    align-items: initial;
}