/* You can add global styles to this file, and also import other style files */
$spacer: 16px;
@import "bootstrap";
@import "./assets/variables.scss";
@import "./assets/general.scss";
@import "./assets/documents-initialize.scss";
@import "./assets/documents-add.scss";
@import "./assets/documents-upload.scss";
@import "./assets/solicitari.scss";
@import "./assets/upload-wrapper.scss";
@import "./assets/angular-layout-override.scss";

// bootstrap overides
ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 16px;
    padding-left: 32px;
}

.row {
    --bs-gutter-x: 16px;
}

.w-100 {
    width: 100%;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
    --bs-gutter-x: 24px;
}

.mat-mdc-form-field-infix {
    margin-top: 6px !important;
}

.mat-body,
.mat-body-2,
.mat-typography {
    letter-spacing: 0.28px;
}

.mat-mdc-dialog-container {
    --mdc-dialog-subhead-tracking: 0.2px;
    --mdc-dialog-supporting-text-tracking: 0.5px;
}

.form-control {
    padding: 6px 12px;
    font-size: 16px;
}

// Button classes
.btn-sm,
.btn-group-sm,
.btn {
    --bs-btn-padding-y: 4px;
    --bs-btn-padding-x: 8px;
    --bs-btn-font-size: 14px;
    --bs-border-radius-sm: 4px;
    --bs-btn-focus-box-shadow: 0 0 0 4px;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

// Material overrides

.mat-mdc-card {
    /* Override with rem values */
    --mat-card-title-text-line-height: 32px;
    /* 32px / 16px */
    --mat-card-title-text-size: 20px;
    /* 20px / 16px */

    /* For em values, you must know the font size of the element to calculate the correct value. 
     Assuming the font-size of the card is 16px (which is 1em), the calculations are the same as for rem */
    --mat-card-subtitle-text-line-height: 22px;
    /* 22px / 16px */
    --mat-card-subtitle-text-size: 14px;
    /* 14px / 16px */

    /* For letter-spacing, convert em to px by multiplying with the current font-size.
     Since 0.0125em is the tracking for the title and 0.0071428571em for the subtitle,
     you need to know the font-size of the title and subtitle to convert these values to pixels. 
     If we assume that title's font-size is 20px and subtitle's font-size is 14px: */
    --mat-card-title-text-tracking: 0.25px;
    /* 0.0125em * 20px */
    --mat-card-subtitle-text-tracking: 0.1px;
    /* 0.0071428571em * 14px */

    /* You typically don't need to convert font-weight, as it's a unitless value. */
    --mat-card-title-text-weight: 500;
    --mat-card-subtitle-text-weight: 500;
}

.mat-typography {
    letter-spacing: 0.2857142864px;
}

p {
    margin-block-start: 16px;
    margin-block-end: 16px;
    font-size: 16px;
}

// Swal overrides

.swal2-confirm.swal2-styled {
    background-color: $primary;
}

// Hides the white backgroud of the Dialog when added to the MatDialog's config object
.custom-modalbox {
    .mat-mdc-dialog-surface.mdc-dialog__surface {
        background-color: transparent !important;
        box-shadow: none;
    }
}

// Adds scrollbal-gutter to the Dialog when added to the MatDialog's config object
.scroll-modalbox {
    .mat-mdc-dialog-surface.mdc-dialog__surface {
        scrollbar-gutter: stable;
    }
}

.swal2-popup {
    font-size: $font-size-body-2 !important;
}

.register-green-text-success {
    background: #69f0ae;
    border: 4px solid #69f0ae;
    border-radius: 2px;
    padding: 10px;
    color: #1b5e20;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.register-green-text-success mat-icon {
    color: #1b5e20 !important;
}

@media only screen and (max-width: 561px) {
    .footer-bar {
        display: flex;
        flex-direction: column;
    }

}

.request-add-infos-card,
.request-add-motivation {
    // margin: 0 10px;
}

@media only screen and (max-width: 1152px) {
    .arrangement-multi-lines-form-fields {
        display: flex;
        flex-direction: column;

        .custom-field {
            width: 100% !important;
        }
    }
}


@media only screen and (max-width: 864px) {
    .form-home-address {
        margin-bottom: 40px;
    }

    .custom-input {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
    }

    .arrangement-multi-lines-form-fields {

        .custom-span {
            margin: 0 50px;
            font-size: larger !important;
        }
    }
}

@media only screen and (max-width: 580px) {
    .m-btm {
        margin-bottom: 100px;
    }

    .custom-column {
        flex-direction: column !important;
        justify-content: space-between;
    }
}

@media only screen and (max-width: 540px) {
    .mobile-view {
        display: flex;
        flex-direction: column;

        .checkbox-border {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

}

@media only screen and (max-width: 720px) {
    .anexa-footer {
        display: flex;
        flex-direction: column !important;

        .spacer {
            margin-top: 20px;
        }
    }

    .checkbox-group {

        .item.custom-item {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 0 10px;
        }
    }
}

.password-conditions-box {
    display: flex;
    width: 100%;
    justify-content: start;
    line-height: 25px;

    p {
        color: $grey-darken-1;
        font-size: 14px;
        font-style: italic;
        opacity: 0.8;
    }
}

// programari web
.ag-theme-material,
.ag-theme-material .ag-header {
    font-family: "Roboto" !important;
    font-weight: 500 !important;
}

.floating-add-button {
    position: absolute !important;
    bottom: 40px;
    right: 20px;
}

.floating-download-button {
    position: absolute !important;
    bottom: 10px;
    left: 10px;
}

.mat-menu-panel .mat-menu-content {
    display: flex;
    flex-direction: column;
}

.unselectable {
    user-select: none;
}

.icon-fix-right {
    margin-right: -10px;
    margin-left: 5px;
}

.icon-fix-left {
    margin-left: -10px;
    margin-right: 5px;
}

.btn-loading-spinner {
    display: inline-block !important;
    margin-left: -10px;
    margin-right: 5px;
}

.container {
    max-width: 1366px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    place-content: center;
}

.cdk-global-overlay-wrapper {
    overflow: overlay !important;
}

.payment-label-container {
    padding-top: 100px;
    text-align: center;
}

.successPaymentLabel {
    color: #47b347;
}

.failPaymentLabel {
    color: #de1c1c;
}

.button {
    border-radius: 4px;
    background-color: $default-white;
    color: $primary-lighten-1;
    border: 1px solid $primary-lighten-1;
    padding: 8px 15px;
}

.section-spinner {
    z-index: 99999;
    background: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    & .mat-mdc-progress-spinner {
        --mdc-circular-progress-active-indicator-color: white;
    }

    & .spinner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: white;
        align-items: center;
    }
}

.white-text {
    color: white !important;
}

// accessibility
@import "./assets/accessibility-tools.scss";