/*** General styles ***/
@import './variables.scss';

.red-text {
    color: $red-accent-4;
}

.w-75 {
    width: 75%;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Roboto;
    font-size: 24px;
    z-index: 1000;
    pointer-events: none;
}

.text-italic {
    margin: 0;
    font-style: italic;
    font-size: 13px;
    font-weight: 400;
    color: $grey-darken-2;

    p {
        font-size: 13px;
        font-weight: 400;
        color: $grey-darken-2;
    }
}