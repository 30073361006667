@import "./variables.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html:focus-within {
    scroll-behavior: smooth;
    font-size: 62.5%;
}

html,
body,
#root {
    height: 100%;
    width: 100%;
}

body {
    text-rendering: optimizeSpeed;
    margin: 0 auto;
    min-width: 344.96px;
}

a {
    text-decoration: none;
}

h6 {
    font-size: $font-size-heading-6 !important;
    font-family: Roboto;
}

.mat-icon {
    font-size: 15px !important;
    height: 15px !important;
    width: 15px !important;
}

.initialize-navigation {
    @media only screen and (min-width: 0px) {
        padding: 12px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .navigation-bar {
            min-width: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 16px;

            .navigation {
                padding: 16px;
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-wrap: wrap;
                row-gap: 16px;
                background-color: $default-white;
                border-radius: 4px;
                width: fit-content;

                &__btn {
                    border-color: $primary-lighten-1;
                    padding: 8px 16px 8px 16px;
                }

                &__breadcrumbs {
                    display: none;
                    cursor: pointer;
                    margin-left: 10px;
                    font-size: $font-size-subtitle-2;
                    font-weight: 400;
                    color: $primary-lighten-1;
                }

                .faded_text {
                    color: $grey-lighten-1;
                }
            }
        }

        .add__request {
            cursor: pointer;
            padding: 16px 24px 16px 24px;
            min-width: fit-content;
            display: flex;
            align-items: center;
            gap: 16px;
            border: 0;
            border-radius: 4px;
            background-color: $primary-lighten-1;

            span {
                color: $default-white;
                font-size: $font-size-body-2;
                font-weight: 500;
                line-height: 36px;
                letter-spacing: 1.25px;
                text-align: center;
            }
        }

        .add__request:hover {
            cursor: pointer;
        }

        .information {
            padding: 12px;
            display: flex;
            flex-direction: column;
            gap: 24px;
            background-color: $default-white;
            min-width: auto;
            border-radius: 4px;

            &-top {
                display: flex;
                flex-direction: row;
                gap: 16px;
            }

            &-appointment-btn-container {
                //min-width: auto;
                //flex-basis: 50%;

                h2 {
                    color: $default-black;
                    font-size: 24px;
                    font-weight: 400;
                }

                h4 {
                    color: $grey-darken-1;
                    font-size: $font-size-body-2;
                    font-weight: 500;
                    font-family: Roboto;
                    margin-top: 10px;
                }

                p {
                    color: $default-black;
                    font-size: $font-size-body-2;
                    font-weight: 600;
                    margin-top: -15px;
                }

                button {
                    font-size: $font-size-subtitle-2;
                    font-weight: 400;
                    color: $primary-lighten-1;
                    background-color: $default-white;
                    border-radius: 4px;
                    gap: 10px;
                    padding: 10px 50px;
                    height: auto;
                    border: 1px solid $primary-lighten-1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    cursor: pointer;

                    &:hover {
                        background-color: $primary-lighten-1;
                        color: $default-white;

                        img {
                            filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(79deg) brightness(103%) contrast(102%);
                        }
                    }
                }
            }

            &-data-container {

                //min-width: auto;
                //flex-basis: 50%;
                //margin-top: 35px;
                h4 {
                    color: $grey-darken-1;
                    font-size: $font-size-body-2;
                    font-weight: 500;
                    font-family: Roboto;
                    margin-top: 10px;
                }

                p {
                    color: $default-black;
                    font-size: $font-size-body-2;
                    font-weight: 600;
                    margin-top: -15px;
                    //display: flex;
                    //flex-direction: column;
                }
            }

            .regulatory-acts {
                h2 {
                    color: $default-black;
                    font-size: 19px;
                    font-weight: 400;
                }

                &-container {
                    div {
                        display: flex;
                        align-items: center;

                        p {
                            flex-basis: 800%;
                            font-size: $font-size-caption;
                            line-height: 15.6px;
                        }

                        button {
                            font-size: $font-size-caption;
                            font-weight: 400;
                            color: $primary-lighten-1;
                            background-color: $default-white;
                            border-radius: 4px;
                            gap: 10px;
                            padding: 2px 10px;
                            height: auto;
                            border: 1px solid $primary-lighten-1;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            margin-bottom: 15px;

                            &:hover {
                                background-color: $primary-lighten-1;
                                color: $default-white;

                                img {
                                    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(79deg) brightness(103%) contrast(102%);
                                }
                            }
                        }
                    }
                }
            }
        }

        .required-documents {
            padding: 12px;
            background-color: $default-white;
            min-width: auto;
            border-radius: 4px;

            h2 {
                color: $default-black;
                font-size: 24px;
                font-weight: 400;
            }

            h4 {
                color: $primary-lighten-1;
                font-size: $font-size-body-2;
                font-weight: 500;
                font-family: Roboto;
                margin-top: 25px;
            }

            p {
                font-size: $font-size-body-1;
                color: $grey-darken-1;
                font-weight: 600;
                margin-top: -10px;
                display: flex;
                flex-direction: column;
            }
        }
    }

    // 576px
    @media only screen and (min-width: $mediaBp4Width) {
        .navigation-bar {
            .navigation {
                width: 100%;

                &__breadcrumbs {
                    display: inline;
                    cursor: pointer;
                    margin-left: 10px;
                    font-size: $font-size-subtitle-2;
                    font-weight: 400;
                    color: $primary-lighten-1;
                }
            }
        }
    }

    // 960px
    @media only screen and (min-width: $mediaBp1Width) {
        padding: 24px;

        .navigation-bar {
            flex-direction: row;
        }

        .information {
            padding: 24px;
            background-color: $default-white;
            min-width: auto;
            display: flex;
            justify-content: space-between;
            gap: 20px;

            &-appointment-btn-container {
                //min-width: auto;
                // flex-basis: 40%;
                //width: 550px;

                .title_text {
                    color: $default-black;

                    font-size: 24px;
                    font-weight: 400;
                }

                h4 {
                    color: $grey-darken-1;
                    font-size: $font-size-body-2;
                    font-weight: 500;
                    font-family: Roboto;
                    margin-top: 10px;
                }

                p {
                    color: $default-black;
                    font-size: $font-size-body-2;
                    font-weight: 600;
                    margin-top: -15px;
                }

                button {
                    font-size: $font-size-subtitle-2;
                    font-weight: 400;
                    color: $primary-lighten-1;
                    background-color: $default-white;
                    border-radius: 4px;
                    gap: 10px;
                    padding: 10px 50px;
                    height: auto;
                    border: 1px solid $primary-lighten-1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    cursor: pointer;
                }
            }

            &-data-container {
                //min-width: auto;
                // flex-basis: 50%;
                margin-top: 35px;

                h4 {
                    color: $grey-darken-1;
                    font-size: $font-size-body-2;
                    font-weight: 500;
                    font-family: Roboto;
                    margin-top: 10px;
                }

                p {
                    color: $default-black;
                    font-size: $font-size-body-2;
                    font-weight: 600;
                    margin-top: -15px;
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .required-documents {
            padding: 24px;

            h2 {
                margin-bottom: 50px;
            }
        }
    }
}