@import "./variables.scss";

* {
    box-sizing: border-box;
}

:host ::ng-deep .mat-mdc-card {
    background-color: transparent;
    box-shadow: none;

    &:hover {
        background-color: none;
        box-shadow: none;
    }

    &:active {
        background-color: none;
        box-shadow: none;
    }
}

.mp {
    width: 100px;
}

.flex {
    display: flex;
    align-items: center;
}

.mat-icon {
    font-size: 15px !important;
    height: 15px !important;
    width: 15px !important;
}

mat-form-field {
    width: 100%;
}

mat-card {
    padding: 24px;
}

mat-card-title {
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: $black-087;
    padding-bottom: 16px;
}

mat-card-subtitle {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: $black-087;
    padding-bottom: 16px;
}

mat-hint {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: $red-accent-3;
}

mat-radio-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.mat-divider {
    margin: 10px 0;
}

.request-add {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-navigation {
        min-width: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        .navigation {
            padding: 16px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            row-gap: 12px;
            background-color: $default-white;
            border-radius: 4px;
            width: 100%;

            &__btn {
                border-color: $primary-lighten-1 !important;
                padding: 8px 16px 8px 16px;
            }

            &__breadcrumbs {
                cursor: pointer;
                margin-left: 10px;
                font-size: $font-size-subtitle-2;
                font-weight: 400;
                color: $primary-lighten-1;
            }

            .faded_text {
                color: $grey-lighten-1;
            }
        }

        .add__docs {
            padding: 16px 24px 16px 24px;
            min-width: fit-content;
            display: flex;
            align-items: center;
            gap: 16px;
            border: 0;
            border-radius: 4px;
            background-color: $primary-lighten-1;

            span {
                color: $default-white;
                font-size: $font-size-body-2;
                font-weight: 500;
                line-height: 36px;
                letter-spacing: 1.25px;
                text-align: center;
            }

            mat-icon {
                color: rgba($default-white, 0.6);
            }
        }
    }

    &-infos {
        gap: 16px;

        &-card {
            flex-basis: 60% !important;
            padding: 24px;

            .bottom,
            .top-info,
            .bottom-info {
                display: flex;
                flex-direction: column;
                gap: 16px;
                margin: 10px 0;
            }
        }

        &-card-info {
            padding: 24px;
            border-radius: 4px;
            background-color: $grey-lighten-5;
        }

        .form-home-address {
            .form-row {
                display: flex;
                flex-direction: row;
                gap: 10px;

                .item-street {
                    flex: 78%;
                }

                .item-number {
                    flex: 18%;
                }

                .info {
                    flex: 40%;
                }

                .item-observations {
                    textarea {
                        height: 100%;
                    }
                }
            }
        }
    }

    &-motivation {
        mat-card-subtitle {
            padding: 8px 0px 8px 0px;
            line-height: 26px;
            letter-spacing: 0.5px;
        }

        &-card {
            display: flex;
            flex-direction: column;
            gap: 24px;
            padding: 24px;
            border-radius: 4px;
            background-color: $grey-lighten-5;
        }

        .form-motivation {
            display: flex;
            flex-direction: column;
            gap: 14px;

            .circle {
                min-width: 24px;
                min-height: 24px;
                border-radius: 50%;
                text-align: center;
                font-size: 12px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.5px;
                color: $default-white;
            }

            .circle.one {
                background-color: $primary-lighten-1;
            }

            .circle.two {
                background-color: $primary-lighten-2;
            }

            .circle.three {
                align-self: flex-start;
                background-color: $primary-lighten-3;
            }

            .checkbox-group {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }

            .item {
                padding: 16px 8px 16px 16px;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 16px;
                border: 1px solid $grey-lighten-2;
                border-radius: 4px;

                .text {
                    width: 100%;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0.5px;
                    color: $black-06;
                }
            }

            .item.two {
                background-color: $grey-lighten-4;

                .text {
                    align-self: flex-start;
                    font-size: 14px;
                    line-height: 21px;
                }
            }

            &-field {
                display: flex;
                flex-direction: column;
                gap: 16px;

                &-title {
                    .title {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 8px;
                    }

                    .description {
                        padding: 0px 32px 0px 32px;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                        color: $grey-darken-2;
                    }
                }

                &-content {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                }
            }

            &-end {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 32px;

                &-field {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                }
            }
        }
    }

    .identify {
        &-items {
            border: 1px solid $grey-lighten-2;
            column-gap: 32px;
            row-gap: 16px;
            border-radius: 4px;
        }

        &-item {
            display: flex;
            justify-content: space-between;
            font-size: $font-size-subtitle-2;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.5px;
            color: $grey-darken-1;
            padding: 16px 8px;

            .bold {
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 0.25px;
                color: $grey-darken-4;
            }
        }
    }

    .address {
        padding: 0px 16px 0px 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &-title {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 1.25px;
            text-transform: uppercase;
            color: $grey-lighten-1;
        }

        &-items {
            display: flex;
            gap: 8px;
        }

        &-items-row {
            display: flex;
            flex-direction: row;
            row-gap: 8px;
            column-gap: 16px;
        }

        &-item {
            display: flex;

            gap: 4px;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.25px;
            color: $grey-darken-1;

            .bold {
                font-weight: 600;
                color: $grey-darken-4;
            }
        }
    }

    &-info {
        margin-top: 5px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        border-radius: 4px;
        border: 1px solid $orange-darken-1;
        color: $orange-darken-1;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
        min-width: auto;

        mat-icon {
            text-align: center;
            min-width: 24px;
        }
    }

    &-button {
        width: 100%;
        padding: 8px 16px 8px 16px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 1.25px;
        text-align: center;
        text-transform: uppercase;

        img {
            color: rgba($primary, 0.6);
        }

        mat-icon {
            text-align: center;
            min-width: 24px;
        }
    }

    &-button-two {
        color: $primary !important;
        border: 1px solid rgba($primary, 0.6) !important;
    }

    .section-spinner {
        z-index: 99999;
        background: rgba(0, 0, 0, 0.6);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        & .mat-mdc-progress-spinner {
            --mdc-circular-progress-active-indicator-color: white;
        }

        & .spinner {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: white;
            align-items: center;
        }
    }
}

// 1260px
@media only screen and (max-width: $mediaMaxWidth) {
    .request-add {
        &-navigation {
            flex-direction: column;
            gap: 16px;
            align-items: center;
        }

        &-infos {
            flex-direction: column;

            &-card {
                flex: none;
                width: auto;
            }
        }

        &-motivation {
            .form-motivation-field:first-child {
                margin-bottom: 20px;
            }
        }
    }
}

// 768px
@media only screen and (max-width: $mediaBp2Width) {
    .request-add {
        &-infos {
            .form-home-address {
                .form-row {
                    .item-street {
                        flex: 1;
                    }

                    .item-number {
                        flex: 1;
                    }
                }

                .form-row-two {
                    display: block;
                }
            }
        }

        &-motivation {
            .form-motivation {
                &-end {
                    flex-direction: column;
                }
            }
        }
    }
}

// 480px
@media only screen and (max-width: $mediaMinWidth) {
    .request-add {
        padding: 16px 0px;

        &-navigation {
            .navigation {
                width: fit-content;

                &__breadcrumbs {
                    display: none;
                }
            }

            .add__docs {
                margin-left: 16px;
                margin-right: 16px;
            }
        }

        &-infos {
            &-card {
                padding: 12px;
            }
        }

        &-motivation {
            &-card {
                padding: 12px;
            }

            .form-motivation {
                .item {
                    padding: 8px 2px 8px 2px;
                    gap: 2px;
                }

                &-field {
                    &-content {
                        padding: 0px;
                    }
                }

                &-end {
                    flex-direction: column;

                    &-field {
                        .signature-field {
                            flex-direction: column;

                            .signature {
                                flex: none;
                            }

                            .delete {
                                flex: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

// .date-picker {
//     width: 360px !important;
// }

.add__docs {
    padding: 6px 24px 6px 24px;
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
    background-color: $primary-lighten-1;
    height: 80px;
    border: 0;

    span {
        color: $default-white;
        font-size: $font-size-body-2;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 1.25px;
        text-align: center;
    }

    mat-icon {
        color: rgba($default-white, 0.6);
    }
}

.nav-btn {
    min-width: 270px;
    padding: 6px 12px 6px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    border-radius: 4px;

    height: 68px;
    border: 0;

    &.btn-primary {
        background-color: $primary-lighten-1;

        &:hover {
            background-color: lighten($primary-lighten-1, 10);
        }
    }

    span {
        color: $default-white;
        font-size: $font-size-body-2;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 1.25px;
        text-align: center;
    }

    mat-icon {
        color: rgba($default-white, 0.6);
    }
}

.cards-wrapper {
    min-width: auto;

    img {
        width: 20px;
        height: 20px;
    }

    .title {
        font-weight: 500;
        font-size: 17px;
    }

    &_info {
        color: $orange-darken-1;
        font-weight: 400;
        font-size: $font-size-body-1;
        border: 1.5px solid $orange-accent-3 !important;
        background-color: $orange-lighten-4 !important;
        padding: 10px;
    }

    &_alert {
        color: $red-darken-4;
        font-weight: 400;
        font-size: $font-size-body-1;
        border: 1.5px solid $red-accent-3 !important;
        background-color: $red-lighten-4 !important;
        padding: 10px;
    }

    .font {
        font-family: Roboto !important;
    }
}

.arrangement-multi-lines-form-fields mat-card-subtitle.mat-mdc-card-subtitle {
    min-height: 70px;
}

.arrangement-multi-lines-form-fields mat-hint {
    font-style: italic;
    font-size: 90%;
    color: $red-accent-3;
}

@media only screen and (max-width: 1052px) {
    .form-motivation-end.mb-3 {
        display: flex;
        flex-direction: column;
    }
}