.upload-container {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid $grey-lighten-2;
    display: flex;
    justify-content: space-between;

    .uploaded-files {
        display: flex;
        flex-direction: column;

        p {
            padding-top: 10px;
            color: $grey-darken-1;
            font-size: $font-size-body-1;
        }

        .success-upload {
            display: flex;
            align-items: center;
            gap: 10px;

            button {
                color: $green-lighten-1;
                border: none;

                .delete-icon {
                    filter: invert(55%) sepia(85%) saturate(3939%) hue-rotate(350deg) brightness(100%) contrast(91%);
                }
            }
        }

    }

    .upload-input {
        z-index: 2;
        position: absolute;
        opacity: 0;
        width: 100px;
        cursor: pointer;
    }

    button {
        height: 40px;
        padding: 0 8px;
        border-radius: 4px;
        border: 1px solid $primary-lighten-1;
        color: $primary-lighten-1;
        background-color: $default-white;
        cursor: pointer;
        text-transform: uppercase;

        &:hover {
            background-color: darken($default-white, 10);
        }

        span {
            margin-right: 5px;
            cursor: pointer;
        }
    }
}